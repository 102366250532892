import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        keywords: [],
        shopkey: undefined,
        shopname: undefined,
        shoptype: 0,
        clears: [false, false, false, false, false, false],
        checkTos: false,
        hashPrefix: "kyshinkey",
        lastedPage: undefined,
        frames: [
            "/img/photo/frame_a.png",
            "/img/photo/frame_b.png"
        ]
    },
    getters: {
        clears: state => state.clears,
        keywords: state => state.keywords,
        shopkey: state => state.shopkey,
        shopname: state => state.shopname,
        shoptype: state => state.shoptype,
        hashPrefix: state => state.hashPrefix,
        isCheckTos: state => state.checkTos,
        lastedPage: state => state.lastedPage,
        frames: state => state.frames,
    },
    mutations: {
        shop(state, data) {
            // console.log("SHOP", data);
            if (!data) return;
            state.shopname = data.name;
            state.shoptype = data.type;
            state.keywords = data.keywords;
            if (data.frames) {
                state.frames = data.frames;
            }
        },
        init(state, data) {
            console.log("INIT", data);
            if (!data) return;
            state.checkTos = data.checkTos;
            if (data.clears) {
                state.clears = data.clears;
            }
            if (data.lastedPage) {
                state.lastedPage = data.lastedPage;
            }
        },
        clearKeyword1(state, data) {
            console.log("CLEAR KEYWORD 1");
            state.clears[0] = true;
        },
        clearKeyword2(state, data) {
            console.log("CLEAR KEYWORD 2");
            state.clears[1] = true;
        },
        clearKeyword3(state, data) {
            console.log("CLEAR KEYWORD 3");
            state.clears[2] = true;
        },
        clearKeyword4(state, data) {
            console.log("CLEAR KEYWORD 4");
            state.clears[3] = true;
        },
        clearKeyword5(state, data) {
            console.log("CLEAR KEYWORD 5");
            state.clears[4] = true;
        },
        clearKeyword6(state, data) {
            console.log("CLEAR KEYWORD 6");
            state.clears[5] = true;
        },
        setCheckTos(state, data) {
            state.checkTos = data;
        },
        setLastedPage(state, data) {
            state.lastedPage = data;
        }
    },
    actions: {
        // ショップ情報を取得
        checkParam(ctx) {
            const params = new URLSearchParams(location.search);
            ctx.state.shopkey = params.get("key");
            // console.log("-- SHOPKEY STORE", ctx.state.shopkey);
            return params;
        },
        // 全部クリアしてるかチェック
        checkAllClear: (ctx) => {
            if (ctx.state.keywords.length != 6) {
                return false;
            }
            let isClear = true;
            for (let i = 0; i < ctx.state.clears.length; i++) {
                if (!ctx.state.clears[i]) {
                    isClear = false;
                    break;
                }
            }
            return isClear;
        },
    }
})