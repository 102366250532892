<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="ma-0 pa-0 headerArea">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100vw"
          style="margin: 0; padding: 0;" viewBox="0 0 390 584" ref="otherarea">
          <image x="0" y="0" width="390" height="582" href="img/present/bg_top.png"></image>
          <image x="0" y="79" width="374" height="375" href="img/present/char_all.png" ref="congImg"
            class="congAnimeDef">
          </image>
          <rect x="0" y="0" width="390" height="90" fill="transparent" @click="goTop()" />
        </svg>
      </v-col>
      <v-col cols="12" class="ma-0 pa-0 contentsArea">
        <div class="wallpaperTitle">
          <img src="img/present/title_pre1.png">
        </div>
        <div class="wallpaperArea">
          <img src="img/present/wallpaper.jpg" class="wallpaperImg">
          <img src="img/present/wp_atte.png" class="wallpaperAttention">
        </div>
      </v-col>
      <v-col cols="12" class="text-center ma-0 pa-0 gophotoArea" ref="gophotoarea">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100vw"
          viewBox="0 0 390 485">
          <g transform="translate(0 0)">
            <image width="390" height="463" href="img/present/footer.jpg"></image>
          </g>
        </svg>
        <div class="mb-12">
          <button class="clearBtn" @click="goPhoto()">撮影してみる</button>
        </div>

      </v-col>

      <v-col cols="12" class="text-center ma-0 pa-0" inert>
        <div ref="scrollAnime" class="scrollAnime"></div>
      </v-col>

    </v-row>
  </v-container>
</template>

<style scoped>
svg {
  width: min(800px, 100%);
}

.headerArea {
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #FF6B00;
  background: linear-gradient(180deg, #FF0000 0%, #FF0000 50%, #FF6B00 50%, #FF6B00 100%);
  white-space: nowrap;
  word-break: break-all;
  vertical-align: bottom;
  display: inline-block;
}

.gophotoArea {
  background-color: #ffb500;
  top: 0;
  left: 0;
}

.contentsArea {
  background-color: #ff6b00;
  position: relative;
}

.wallpaperTitle {
  width: 100%;
  text-align: center;
  position: relative;
  top: min(20px, 10vw);
}

.wallpaperTitle img {
  width: 100%;
}

.wallpaperArea {
  position: relative;
  top: max(-20px, -6vw);
  padding: 0 min(100px, 9vw);
  text-align: center;
}

.wallpaperImg {
  background-image: url(@/assets/img/photobg.svg);
  background-size: cover;
  padding: min(20px, 2.5vw);
  width: 100%;
}

.wallpaperAttention {
  width: 60%;
  margin-top: min(10px, 2vw);
}

.congAnime {
  opacity: 0;
  animation: charaAnime 1s ease-in-out forwards;
  transform-origin: 50% 50%;
}

.congAnimeDef {
  opacity: 0;
}

@keyframes charaAnime {
  0% {
    scale: 0.3;
    opacity: 0;
  }

  30% {
    scale: 1;
    opacity: 1;
  }

  40% {
    opacity: 1;
  }

  50% {
    opacity: 1;
    scale: 0.9;
  }

  75% {
    /* rotate: 0deg; */
    opacity: 1;
    scale: 1.02;
  }

  100% {
    opacity: 1;
    rotate: 0deg;
  }
}

.shininfo {
  position: fixed;
  top: min(216px, 27vw);
  left: 5vw;
  width: min(300px, 40vw);
  height: min(400px, 50vw);
  background-size: contain;
  animation: fadeIn 1s ease-in-out forwards, flyAnime 5s ease infinite;
  animation-delay: 1s;
  opacity: 0;
}


@keyframes flyAnime {
  0% {
    transform: translate(0px, 0px);
  }

  50% {
    transform: translate(-6px, 10px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.scrollAnime {
  position: fixed;
  bottom: 3vw;
  left: 2vw;
  background-image: url(@/assets/img/scrollarrow.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 10vw;
  height: 12vw;
}

.fadeIn {
  animation: fadeIn 0.3s ease-in-out forwards;
}

.fadeOut {
  animation: fadeOut 0.3s ease-in-out forwards;
}

.clearBtn {
  font-family: 'Noto Sans JP', sans-serif;
  font-size: min(34px, 4.5vw);
  color: white;
  width: 11em;
  border: 0.15em solid #015014;
  border-radius: 0.8em;
  padding: 0.3em 0;
  margin: 0 -0.5em 0 0;
  background-color: #2B8941;
  text-align: center;
  font-weight: bold;
  animation: flybtnAnime 5s 1s ease infinite;
}

@keyframes flybtnAnime {
  0% {
    transform: scale(1.0, 1.0) translate(0%, 0%);
  }

  4% {
    transform: scale(0.95, 0.95) translate(0%, 0%);
  }

  7% {
    transform: scale(1.05, 0.9) translate(0%, 0.02%);
  }

  13% {
    transform: scale(0.9, 1.05) translate(0%, -0.02%);
  }

  17% {
    transform: scale(1.05, 0.95) translate(0%, 0.005%);
  }

  70% {
    transform: scale(1.0, 1.0) translate(0%, 0%);
  }

  100% {
    transform: scale(1.0, 1.0) translate(0%, 0%);
  }
}
</style>
<script>

export default {
  components: {
  },
  data: () => ({
    scobserver: undefined,
    sctarget: undefined,
  }),
  created: async function () {

    // データがあって全問正解してるかチェック
    if (!await this.$store.dispatch("checkAllClear")) {
      this.$router.replace({ name: 'loading', query: { key: this.$store.getters.shopkey } });
      return;
    }
    window.scrollBy(0, 0);

    // 下までscrollしたときのアクション
    // window.addEventListener('scroll', this.scrollEvent);


  },
  mounted: function () {
    if (this.$refs.congImg) {
      let targetImg = this.$refs.congImg;
      targetImg.addEventListener("load", () => {
        targetImg.classList.add("congAnime");
      });
      setTimeout(() => {
        targetImg.classList.add("congAnime");
      }, 1000);
    }

    this.sctarget = this.$refs.gophotoarea;
    this.scobserver = new IntersectionObserver((entry) => {
      entry.forEach((item) => {
        if (item.target === this.sctarget) {
          if (item.isIntersecting) {
            this.$refs.scrollAnime.classList.add("fadeOut");
            this.$refs.scrollAnime.classList.remove("fadeIn");

          } else {
            this.$refs.scrollAnime.classList.add("fadeIn");
            this.$refs.scrollAnime.classList.remove("fadeOut");
          }
        }
      });
    }, {
      root: null, //ターゲットが見えるかどうかを確認するためのビューポート, nullはブラウザビューポート
      rootMargin: '0px', //rootの周りのマージンの値
      threshold: 0.3 //どの程度表示されたか
    });
    this.scobserver.observe(this.sctarget);

  },

  destroyed: function () {
    if (this.scobserver) {
      this.scobserver.unobserve(this.sctarget);
    }
    // window.removeEventListener('scroll', this.scrollEvent);

  },
  methods: {
    goPhoto() {
      this.$router.push({ name: 'photo', query: { key: this.$store.getters.shopkey } });
    },
    goTop() {
      this.$router.push({ name: 'start', query: { key: this.$store.getters.shopkey } });
    },
    // scrollEvent() {
    //   // 対象のdivが表示されたか
    //   // console.log("scroll", window.scrollY, this.$refs.gophotoarea.offsetHeight);



    //   // console.log("scroll", (window.innerHeight + window.scrollY), document.body.offsetHeight);
    //   // if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight * 0.8) {
    //   //   this.$refs.scrollAnime.classList.add("fadeOut");
    //   //   this.$refs.scrollAnime.classList.remove("fadeIn");
    //   // } else {
    //   //   this.$refs.scrollAnime.classList.add("fadeIn");
    //   //   this.$refs.scrollAnime.classList.remove("fadeOut");
    //   // }
    // }
  },
  watch: {

  }
};
</script>
