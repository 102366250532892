<template>
  <v-container class="bgerror">

    <v-row class="text-center pt-5 pl-10 pr-10">
      <v-col cols="12" class="title">
        Error
      </v-col>
      <v-col cols="12">
        <img src="img/error/char.png" class="shinmessage">
      </v-col>
      <v-col cols="12">
        <ul class="errormsg">
          <li v-for="message in errorMessageList">{{ message }}</li>
          <li v-if="cameraerrorpage">
            iOSの場合は下記の＜戻る＞を押してから表示されるカメラ利用の許可を押してください。
          </li>
          <li v-if="cameraerrorpage">
            Androidの場合は以下のリンクを参考に設定を変更してから＜戻る＞を押してください。<a
              href='https://support.google.com/chrome/answer/2693767?hl=ja&co=GENIE.Platform%3DAndroid'
              target="_blank">Google Chrome ヘルプ：カメラとマイクを使用する</a>
          </li>
        </ul>
      </v-col>
      <v-col cols="12">
        <svg width="130" height="45" viewBox="0 0 130 45" fill="none" xmlns="http://www.w3.org/2000/svg"
          v-if="historyCount != 1" @click="goBack">
          <rect x="1" y="1" width="128" height="43" rx="14" fill="#2B8941" stroke="#015014" stroke-width="2" />
          <path
            d="M63.63 15.1V17.24H46.67V15.1H63.63ZM50.15 25.08H54.85C54.91 24.54 54.95 23.94 54.99 23.32H50.27C50.25 23.88 50.21 24.48 50.15 25.08ZM60.35 20H50.29V21.52H60.35V20ZM63.67 27.14H57.87C58.97 29.16 61.05 30.22 64.45 30.58C63.95 31.08 63.33 32.06 63.05 32.72C59.69 32.18 57.65 30.94 56.31 28.74C55.35 30.62 53.57 31.92 50.13 32.76C49.93 32.18 49.33 31.26 48.85 30.8C52.23 30.08 53.69 28.92 54.37 27.14H49.85C49.47 29.08 48.79 31.02 47.61 32.5C47.17 32.1 46.11 31.48 45.51 31.24C47.71 28.56 47.91 24.66 47.91 21.96V18.18H62.69V23.32H57.43C57.41 23.94 57.35 24.52 57.29 25.08H63.67V27.14ZM72.75 28.64C72.75 29.38 73.57 29.88 74.77 29.88C75.19 29.88 75.57 29.86 75.93 29.82C75.81 28.52 75.07 27.68 73.99 27.68C73.23 27.68 72.75 28.14 72.75 28.64ZM69.35 15.76C69.91 15.84 70.61 15.86 71.17 15.86C72.23 15.86 76.51 15.78 77.65 15.68C78.47 15.6 78.93 15.54 79.17 15.46L80.47 17.24C80.01 17.54 79.53 17.82 79.07 18.16C77.99 18.92 75.89 20.68 74.63 21.68C75.29 21.52 75.89 21.46 76.53 21.46C79.93 21.46 82.51 23.58 82.51 26.5C82.51 29.66 80.11 32.16 75.27 32.16C72.49 32.16 70.39 30.88 70.39 28.88C70.39 27.26 71.85 25.76 73.97 25.76C76.45 25.76 77.95 27.28 78.23 29.18C79.33 28.6 79.89 27.68 79.89 26.46C79.89 24.66 78.09 23.44 75.73 23.44C72.67 23.44 70.67 25.06 68.53 27.5L66.71 25.62C68.13 24.48 70.55 22.44 71.73 21.46C72.85 20.54 74.93 18.86 75.91 18C74.89 18.02 72.13 18.14 71.07 18.2C70.53 18.24 69.89 18.3 69.43 18.36L69.35 15.76Z"
            fill="white" />
        </svg>


        <!-- <img src="img/error/btnback.png" v-if="historyCount != 1" @click="goBack" class="btnback"> -->
      </v-col>

    </v-row>

  </v-container>
</template>
<style scoped>
ul {
  list-style: none;
  text-align: center;
}

.title {
  font-family: 'Noto Sans JP', sans-serif;
  font-size: min(40px, 5vw);
  color: #ffffff;
  font-weight: bold;
  margin-bottom: min(40px, 5vw);
}

.bgerror {
  background-color: #ff0000;
  height: 100vh;
}

.shinmessage {
  margin-top: min(40px, 5vw);
  width: min(320px, 40vw);
}

.errormsg {
  text-align: center;
  margin-left: min(40px, 5vw);
  padding: min(40px, 5vw);
  font-family: 'Noto Sans JP', sans-serif;
  font-size: min(24px, 3vw);
  background-color: #FFF5DE;
  border-radius: 1em;
  margin: 0;
}

.btnback {
  margin-top: min(40px, 5vw);
  width: min(320px, 40vw);
}
</style>

<script>
export default {
  data: () => ({
    errorMessageList: ["QRコードをもう一度読んでください。"],
    historyCount: -1,
    cameraerrorpage: false
  }),
  created: function () {
    this.historyCount = history.length;
    if (this.$route.params.errormessage) {
      this.errorMessageList.unshift(this.$route.params.errormessage);
    }
    if (this.$route.params.errorcamera) {
      this.errorMessageList = ["カメラデバイスの利用ができませんでした。"];
      this.cameraerrorpage = true;
    }
  },
  methods: {
    async goBack() {
      history.back();
      // window.location.href = window.location.href;
    },
  },
}
</script>
