<template>
    <v-container>
        <v-row>
            <v-col cols="12" class="ma-0 pa-0 headerArea">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100vw"
                    style="margin: 0; padding: 0;" viewBox="0 0 390 1101" ref="otherarea">
                    <g transform="translate(0 -25)">
                        <g>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M0 289H390V1131.92V1132.06H389.619L195 1203.49L0.381485 1132.06H0V1131.92V289Z"
                                fill="#FF6B00" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M0 -188H390V655.06L195 726.63L0 655.06V-188Z" fill="#FF0000" fill-opacity="0.98" />

                            <image x="0" y="116" width="390" height="562" href="img/top/parts/bg_red.png"></image>
                            <image x="0" y="723" width="390" height="364" href="img/top/parts/bg_ora.png"></image>
                            <image x="117" y="44" width="232" height="87" href="img/top/parts/title_t.jpg"></image>
                        </g>

                        <image x="294" y="404" width="86" height="86"
                            :href="'shops/' + $store.getters.shopkey + '/topdate.png'">
                        </image>

                        <g>
                            <image x="16" y="163" width="90" height="118" href="img/top/parts/chara_bou.png"
                                class="chara01">
                            </image>
                            <image x="300" y="181" width="90" height="100" href="img/top/parts/chara_nene.png"
                                class="chara02">
                            </image>
                            <image x="35" y="318" width="101" height="103" href="img/top/parts/chara_masao.png"
                                class="chara03">
                            </image>
                            <image x="274" y="265" width="107" height="147" href="img/top/parts/chara_kazama.png"
                                class="chara04">
                            </image>
                            <image x="123" y="291" width="151" height="147" href="img/top/parts/chara_shin.png"
                                class="chara05">
                            </image>
                            <image x="183" y="376" width="134" height="137" href="img/top/parts/chara_nana.png"
                                class="chara06">
                            </image>
                            <image x="60" y="400" width="135" height="89" href="img/top/parts/chara_shiro.png"
                                class="chara07">
                            </image>
                        </g>

                        <image x="16" y="201" width="357" height="139" href="img/top/parts/title_m.png">
                        </image>
                        <image x="7" y="503" width="375.5" height="103.5" href="img/top/parts/title_b.png">
                        </image>

                        <g transform="translate(16 898)" v-if="wordoffImgs.length == 6">
                            <g transform="translate(0 0)">
                                <image width="92" height="84" :href="wordoffImgs[0].src" v-if="!wordClaers[0]"></image>
                                <image width="92" height="84" :href="wordonImgs[0].src" v-if="wordClaers[0]"></image>
                            </g>
                            <g transform="translate(59 46)">
                                <image width="71" height="75" :href="wordoffImgs[1].src" v-if="!wordClaers[1]"></image>
                                <image width="71" height="75" :href="wordonImgs[1].src" v-if="wordClaers[1]"></image>
                            </g>
                            <g transform="translate(104 5)">
                                <image width="86" height="85" :href="wordoffImgs[2].src" v-if="!wordClaers[2]"></image>
                                <image width="86" height="85" :href="wordonImgs[2].src" v-if="wordClaers[2]"></image>
                            </g>
                            <g transform="translate(181 17)">
                                <image width="79" height="71" :href="wordoffImgs[3].src" v-if="!wordClaers[3]"></image>
                                <image width="79" height="71" :href="wordonImgs[3].src" v-if="wordClaers[3]"></image>
                            </g>
                            <g transform="translate(232 48)">
                                <image width="69" height="72" :href="wordoffImgs[4].src" v-if="!wordClaers[4]"></image>
                                <image width="69" height="72" :href="wordonImgs[4].src" v-if="wordClaers[4]"></image>
                            </g>
                            <g transform="translate(273 5)">
                                <image width="83" height="84" :href="wordoffImgs[5].src" v-if="!wordClaers[5]"></image>
                                <image width="83" height="84" :href="wordonImgs[5].src" v-if="wordClaers[5]"></image>
                            </g>
                        </g>
                        <g transform="translate(43 823)">
                            <image width="306" height="89" href="img/top/top_6pointkeyword.png"></image>
                        </g>
                    </g>
                </svg>
            </v-col>
            <v-col cols="12" class="ma-0 pa-0 inputArea">
                <div v-if="!isAllClear">
                    <span id="inputkeyarea">
                        <input type="text" minlength="1" maxlength="1" v-model="inputKey" id="inputkeyword"
                            ref="inputkeyword" placeholder="こたえを入力" @focus="textFocusEvent()" @blur="textBlurEvent()"
                            :disabled="inputDisabled">
                    </span>
                    <div ref="wordJudgmentArea" id="wordJudgmentArea">
                        <div :class="wordJudgementClassKi">
                            <img src="img/top/c_ki.png" width="100%" height="100%" @click="hideJudgment"></img>
                        </div>
                        <div :class="wordJudgementClassYo">
                            <img src="img/top/c_yo.png" width="100%" height="100%" @click="hideJudgment"></img>
                        </div>
                        <div :class="wordJudgementClassU">
                            <img src="img/top/c_u.png" width="100%" height="100%" @click="hideJudgment"></img>
                        </div>
                        <div :class="wordJudgementClassRi">
                            <img src="img/top/c_ri.png" width="100%" height="100%" @click="hideJudgment"></img>
                        </div>
                        <div :class="wordJudgementClassYu">
                            <img src="img/top/c_yu.png" width="100%" height="100%" @click="hideJudgment"></img>
                        </div>
                        <div :class="wordJudgementClassMiss">
                            <img src="img/top/c_mistake.png" width="100%" height="100%" @click="hideJudgment"></img>
                        </div>
                    </div>


                </div>
                <div v-else>
                    <button class="clearBtn"
                        @click="() => $router.push({ name: 'present', query: { key: $store.getters.shopkey } })">プレゼントを受け取る</button>
                </div>
            </v-col>
            <v-col cols="12" class="text-center ma-0 pa-0 footerArea">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100vw"
                    viewBox="0 0 390 423" ref="otherarea">
                    <image width="390" height="403" href="img/top/top_footer.png"></image>
                </svg>
            </v-col>
        </v-row>

    </v-container>
</template>

<style scoped>
svg {
    width: min(800px, 100%);
}

input:focus {
    outline: none;
}

.headerArea {
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: #FF6B00;
    white-space: nowrap;
    word-break: break-all;
    vertical-align: bottom;
    display: inline-block;
}

.footerArea {
    background: linear-gradient(180deg, #FF6B00 0%, #FF6B00 50%, #FFB500 50%, #FFB500 100%);
}

.inputArea {
    position: relative;
    width: 100%;
    height: 5vw;
    background-color: #FF6B00;
    margin: 0;
    padding: 0;
}

.inputArea>div {
    position: absolute;
    top: max(-180px, -24vw);
    width: 100%;
    text-align: center
}

#inputkeyword::placeholder {
    color: white;
}

#inputkeyword:focus::-webkit-input-placeholder {
    color: transparent;
}

#inputkeyword:focus::-moz-placeholder {
    color: transparent;
}

#inputkeyword:focus::-ms-input-placeholder {
    color: transparent;
}

#inputkeyword:focus::placeholder {
    color: transparent;
}

#inputkeyword:disabled {
    color: #FFFFFF;
    width: 17em;
    border: 0.15em solid #015014;
    border-radius: 2em;
    padding: 0.7em 1.4em;
    background-color: #2B8941;
}

#inputkeyword {
    font-family: 'Noto Sans JP', sans-serif;
    font-size: min(42px, 5.5vw);
    color: white;
    width: 11em;
    border: 0.15em solid #015014;
    border-radius: 0.8em;
    padding: 0.3em 0;
    margin: 0 -0.5em 0 0;
    background-color: #2B8941;
    text-align: center;
    font-weight: bold;
    animation: flybtnAnime 5s 1s ease infinite;
}

.clearBtn {
    font-family: 'Noto Sans JP', sans-serif;
    font-size: min(34px, 4.5vw);
    color: white;
    width: 11em;
    border: 0.15em solid #015014;
    border-radius: 0.8em;
    padding: 0.3em 0;
    margin: 0 -0.5em 0 0;
    background-color: #2B8941;
    text-align: center;
    font-weight: bold;
    animation: flybtnAnime 5s 1s ease infinite;
}


#inputkeyarea {
    position: relative;
}

@keyframes flybtnAnime {
    0% {
        transform: scale(1.0, 1.0) translate(0%, 0%);
    }

    4% {
        transform: scale(0.95, 0.95) translate(0%, 0%);
    }

    7% {
        transform: scale(1.05, 0.9) translate(0%, 0.02%);
    }

    13% {
        transform: scale(0.9, 1.05) translate(0%, -0.02%);
    }

    17% {
        transform: scale(1.05, 0.95) translate(0%, 0.005%);
    }

    70% {
        transform: scale(1.0, 1.0) translate(0%, 0%);
    }

    100% {
        transform: scale(1.0, 1.0) translate(0%, 0%);
    }
}

@keyframes roteAnime {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(10deg);
    }

    75% {
        transform: rotate(-10deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.chara01 {
    animation: charaAnime 7s ease-in-out infinite alternate-reverse;
    transform-origin: 16% 21%;
}

.chara02 {
    animation: charaAnime 3.5s ease-in-out infinite alternate-reverse;
    transform-origin: 83% 22%;
}

.chara03 {
    animation: charaAnime 2.7s ease-in-out infinite alternate-reverse;
    transform-origin: 17% 33%;
}

.chara04 {
    animation: charaAnime 4.3s ease-in-out infinite alternate-reverse;
    transform-origin: 87% 32%;
}

.chara05 {
    animation: charaAnime 5.3s ease-in-out infinite alternate-reverse;
    transform-origin: 48% 34%;
}

.chara06 {
    animation: charaAnime 8s ease-in-out infinite alternate-reverse;
    transform-origin: 58% 43%;
}

.chara07 {
    animation: charaAnime 3.2s ease-in-out infinite alternate-reverse;
    transform-origin: 24% 39%;
}


@keyframes charaAnime {
    0% {
        transform: translateY(0%);
        /* rotate: 0deg; */
    }

    25% {
        transform: translateY(0.5%);
        /* rotate: 5deg; */
    }

    75% {
        transform: translateY(-0.5%);
        /* rotate: -5deg; */
    }

    100% {
        transform: translateY(0%);
        /* rotate: 0deg; */
    }
}

#wordJudgmentArea>div {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);
    display: none;
}

#wordJudgmentArea>div img {
    object-fit: contain;
    padding: 8vw;
}

.wordJudgmentAnime {
    display: block !important;
    opacity: 0;
    animation: fadeinout 3s ease-in-out forwards 1;
}


@keyframes fadeinout {
    0% {
        opacity: 0;
    }

    10%,
    90% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.wordJudgmentAnime img {
    animation: mochimochi 1s forwards 1;
}

@keyframes mochimochi {
    0% {
        transform: scale(0.2, 0.1);
    }

    20% {
        transform: scale(0.8, 1.1);
    }

    40% {
        transform: scale(1.1, 0.8);
    }

    60% {
        transform: scale(0.85, 1.05);
    }

    80% {
        transform: scale(1.05, 0.9);
    }

    100% {
        transform: scale(1, 1);
    }
}
</style>
<script>

export default {
    components: {
    },
    data: () => ({
        wordoffImgs: [],
        wordonImgs: [],
        wordClaers: [],
        inputKey: "",
        textFocus: false,
        textTimer: undefined,
        inputKeyChatteringTimer: undefined,
        beforeInputKey: "",
        inputFocusCount: 0,
        inputDisabled: false,
        inputFocusChatteringTimer: undefined,
        isAllClear: false,
        wordJudgementClassKi: "",
        wordJudgementClassYo: "",
        wordJudgementClassU: "",
        wordJudgementClassRi: "",
        wordJudgementClassYu: "",
        wordJudgementClassMiss: "",
    }),
    created: async function () {
        if (await this.$store.dispatch("checkAllClear")) {
            this.isAllClear = true;
        }
    },
    mounted: function () {
        this.wordClaers = this.$store.getters.clears;
        let keywords = this.$store.getters.keywords;
        let shopkey = this.$store.getters.shopkey;
        // 読み込みがおかしい時
        if (keywords.length != 6) {
            this.$router.replace({ name: 'loading', query: { key: this.$store.getters.shopkey } });
        }

        for (let i = 0; i < keywords.length; i++) {
            let preloadOff = new Image();
            preloadOff.src = "img/top/word" + (i + 1) + "off.svg";
            let preloadOn = new Image();
            preloadOn.src = "img/top/word" + (i + 1) + "on.svg";

            this.wordonImgs.push(preloadOn);
            this.wordoffImgs.push(preloadOff);
        }

        // this.$router.beforeEach((to, from, next) => {
        //     console.log("★beforeEach", to, from, next)
        //     console.log("animeaaaaaaa");
        //     setTimeout(() => {
        //         console.log("bbbbbb");
        //         next();
        //     }, 10000);
        // })
    },
    destroyed: function () {

    },
    methods: {
        hideJudgment: function () {
            console.log("★hideJudgment")
            // const base = this.$refs.wordJudgmentArea;
            // for (let i = 0; i < base.children.length; i++) {
            //     if (base.children[i].classList.contains("wordJudgmentAnime")) {
            //         base.children[i].classList.remove("wordJudgmentAnime");
            //     }
            // }
            this.wordJudgementClassKi = "";
            this.wordJudgementClassYo = "";
            this.wordJudgementClassU = "";
            this.wordJudgementClassRi = "";
            this.wordJudgementClassYu = "";
            this.wordJudgementClassMiss = "";

        },
        textFocusEvent: function () {
            this.textFocus = true;
            clearTimeout(this.inputFocusChatteringTimer);
            this.inputFocusChatteringTimer = setTimeout(() => {
                if (this.beforeInputKey == "") {
                    this.inputKey = "";
                }
            }, 150);

        },
        textBlurEvent: function () {
            this.textFocus = false;
            // console.log("textBlurEvent", this);
        },
        inputKeyExec: async function () {
            let inputKey = this.inputKey;
            // 拗音の場合は、ひらがなに変換
            const regex = /[ゃゅょっ]/;
            if (regex.test(inputKey)) {
                const map = {
                    ゃ: "や",
                    ゅ: "ゆ",
                    ょ: "よ",
                    っ: "つ",
                };
                inputKey = map[inputKey];
            }
            this.beforeInputKey = inputKey;
            if (this.inputKey == "") {
                return;
            }

            this.$refs.inputkeyword.blur();
            setTimeout(() => {
                this.beforeInputKey = "";
            }, 500);

            let keywords = this.$store.getters.keywords;
            let clears = this.$store.getters.clears;
            let clearNumber = -1;
            for (let i = 0; i < keywords.length; i++) {
                if (keywords[i].word == inputKey) {
                    if (clears[i] == true) {
                        let isSameAnswer = false;
                        for (let j = i; j < keywords.length; j++) {
                            if (keywords[j].word == inputKey) {
                                console.log("★clears", j, clears[j]);
                                if (clears[j] == false) {
                                    isSameAnswer = true;
                                    continue;
                                }
                            }
                        }
                        if (isSameAnswer) {
                            continue;
                        }
                    }
                    clearNumber = Number(i);
                    break;
                }
            }

            this.clearInputForm();

            // 不正解だったら
            if (clearNumber == -1) {
                this.wordJudgementClassMiss = "wordJudgmentAnime";
                return;
            }

            // 答え済みだった場合
            if (clears[clearNumber] != false) {
                this.wordJudgementClassMiss = "wordJudgmentAnime";
                return;
            }

            this.$store.commit("clearKeyword" + (clearNumber + 1), true);
            this.wordClaers = this.$store.getters.clears;

            switch (clearNumber) {
                case 0:
                    this.wordJudgementClassKi = "wordJudgmentAnime";
                    break;
                case 1:
                    this.wordJudgementClassYo = "wordJudgmentAnime";
                    break;
                case 2:
                case 5:
                    this.wordJudgementClassU = "wordJudgmentAnime";
                    break;
                case 3:
                    this.wordJudgementClassRi = "wordJudgmentAnime";
                    break;
                case 4:
                    this.wordJudgementClassYu = "wordJudgmentAnime";
                    break;
            }

            if (await this.$store.dispatch("checkAllClear")) {
                // setTimeout(() => {
                this.$router.push({ name: 'present', query: { key: this.$store.getters.shopkey } });
                // }, 100);
            }
        },
        clearInputForm: function () {
            this.inputKey = "";
            this.beforeInputKey = "";
            setTimeout(() => {
                this.hideJudgment();
            }, 3000);
        },
        inputFocusExec: async function () {
            // console.log("★inputFocusExec", this.inputKey);
        },
    },
    computed: {
    },
    watch: {

        inputKey: async function (newVal, oldVal) {
            // console.log("★inputKey", newVal, "<-", oldVal);

            clearTimeout(this.inputKeyChatteringTimer);
            this.inputKeyChatteringTimer = setTimeout(() => {
                this.inputKeyExec();
            }, 100);


        },
    }
};
</script>
