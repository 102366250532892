<template>
  <v-container class="photoArea">
    <v-row class="text-center fixmargin">
      <v-col cols="12" class="text-center ma-0 pa-0">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 390 90">
          <g transform="translate(0 0)">
            <image width="390" height="90" href="img/photo/title.png"></image>
          </g>
          <rect x="70" y="0" width="250" height="90" fill="transparent" @click="goTop()" />
        </svg>
      </v-col>
    </v-row>

    <v-row class="ma-5 pa-0 ml-10 mr-10">
      <v-col cols="12" class="text-center ma-0 pa-0 frameImgArea loading" ref="prevFrameArea" v-show="!isShotFreeze">
        <img ref="capImage" src="/img/photo/framedummy.png" id="capImage" class="imgPreview" />
        <div class="frameLoading">
          <img src="img/photo/loading.svg" />
        </div>
      </v-col>
      <v-col cols="12" class="text-center ma-0 pa-0" ref="shotFrameArea" v-show="isShotFreeze">
        <img id="photoImage" ref="photoImage" src="" class="imgPreview" />
        <div class="frameLoading">
          <img src="img/photo/loading.svg" />
        </div>
      </v-col>
      <v-col cols="6" class="pa-0 ma-0 pr-2 pt-2">
        <img src="img/photo/btnshot.png" @click="execShot" v-if="!isShotFreeze" width="100%" />
        <img src="img/photo/btnreshot.png" @click="execRetry" v-else width="100%" />
      </v-col>
      <v-col cols="6" class="pa-0 ma-0 pl-2 pt-2">
        <div class="flashAnimeBase">
          <img src="img/photo/btnchange.png" width="100%" @click="changeFrame"
            :class="countShotAttention === 1 ? 'flashAnime' : ''" />
        </div>
      </v-col>
    </v-row>


    <v-row class="infophotosave ma-0 pa-0" inert v-if="isShotFreeze">
      <v-col cols="12" class="ma-0 pa-0">
        <img src="img/photo/shin.png" width="80%" />
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
svg {
  width: min(800px, 100%);
}

.frameImgArea {
  position: relative;
}

.loading>.frameLoading {
  display: block !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading>.imgPreview {
  filter: brightness(0.3);
}

.frameLoading {
  display: none;
}




.photoArea {
  background-color: #FF0000;
  /* height: 100vh; */
}

.btnNormal {
  border: 2px solid black;
  border-radius: 2em;
  font-size: min(3.2vw, 26px);
  font-weight: bold;
  line-height: 1.3em;
  color: black;
  height: 3.7em;
  padding: auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.btnMini {
  border: 1.5px solid black;
  border-radius: 1.5em;
  font-size: min(3.2vw, 16px);
  font-weight: bold;
  line-height: 1.3em;
  color: black;
  height: 2.5em;
  padding: auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.btnBlue {
  background-color: #3BBEE9;
  box-shadow: max(-0.25vw, -2px) max(-0.5vw, -4px) 0px min(0.3vw, 3px) #1390BB inset;
}

.btnRed {
  background-color: #F19069;
  box-shadow: max(-0.25vw, -2px) max(-0.5vw, -4px) 0px min(0.3vw, 3px) #d85b48 inset;
}

.btnMiniRed {
  background-color: #F19069;
  box-shadow: max(-0.12vw, -1px) max(-0.25vw, -2px) 0px min(0.15vw, 1.5px) #d85b48 inset;
}


.btnMiniGray {
  background-color: #E9E9E9;
  box-shadow: max(-0.12vw, -1px) max(-0.25vw, -2px) 0px min(0.15vw, 1.5px) #b8b8b8 inset;
}

#capImage,
#photoImage {
  padding: 8px;
  background-image: url(@/assets/img/photobg.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
}


.infophotosave {
  position: fixed;
  top: 30%;
  left: 0;
  width: 100%;
  z-index: 123;
}

.infophotosave div {
  display: flex;
  justify-content: flex-end;
  animation: slidein 10s ease infinite;
}

@keyframes slidein {

  0%,
  10%,
  50%,
  100% {
    transform: translateY(140vw);
  }

  20%,
  40% {
    transform: translateY(0);
  }


}


.flashAnime {
  animation: flash 3s ease infinite;
}

@keyframes flash {

  0%,
  5%,
  25% {
    opacity: 1;
    transform: scale(1.0, 1.0);
  }

  15% {
    opacity: 0.5;
    transform: scale(1.08, 1.08);
  }
}
</style>
<script>

export default {
  components: {
  },
  data: () => ({
    viewFrame: undefined,
    isShotFreeze: false,
    // isShotAttention: false,
    countShotAttention: 0,
    dialogMaxWidth: 400,
    frameImage: [],
    changeWindowBackgroundCss: undefined
  }),
  created: async function () {
    console.log("PHOTO LOADING")
    // データがあって全問正解してるかチェック
    if (!await this.$store.dispatch("checkAllClear")) {
      this.$router.replace({ name: 'loading', query: { key: this.$store.getters.shopkey } });
      return;
    }

    //フレーム画像DL
    this.loadFrameImage();

    if (this.dialogMaxWidth * 1.5 > window.innerHeight) {
      this.dialogMaxWidth = window.innerHeight / 1.6;
    }
    window.scrollBy(0, 0);

  },
  mounted: function () {

    // フォトフレームを文字列AとBのどちらかランダムに選択する
    this.viewFrame = Math.floor(Math.random() * 2);
    // window.onresize = this.resize;
  },

  destroyed: function () {
    let appDom = document.getElementById("app");
    appDom.classList.remove("photoBgA");
    appDom.classList.remove("photoBgB");
    this.$stamp.stopCamera();
    // window.onresize = undefined;
  },
  methods: {
    goTop() {
      this.$router.push({ name: 'start', query: { key: this.$store.getters.shopkey } });
    },
    async loadFrameImage() {
      let frames = this.$store.getters.frames;
      if (!frames || frames.length != 2) {
        frames = ["/img/photo/frame_a.png", "/img/photo/frame_b.png"];
      }
      this.frameImage[0] = await this.loadImage(frames[0]);
      this.frameImage[1] = await this.loadImage(frames[1]);
      this.viewShot();
    },
    loadImage(src) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = (e) => reject(e);
        img.src = src;
      });
    },
    async viewShot(count, func) {
      if (!count) count = 1;
      if (this.$refs.capImage) {
        try {
          await this.$stamp.shotCamera(this.$refs.capImage, this.frameImage[this.viewFrame], () => {
            this.$refs.prevFrameArea.classList.remove("loading");
            this.$refs.shotFrameArea.classList.remove("loading");
          });
        } catch (error) {
          console.error(error);
          this.$router.replace({ name: 'error', params: { "errorcamera": true }, query: { key: this.$store.getters.shopkey } });
        }
      } else if (count < 5) {
        setTimeout(() => {
          this.viewShot(count + 1, func)
        }, 300);
      }
    },
    execShot() {
      try {
        const shotImage = this.$stamp.getShotCameraData();
        if (!shotImage) throw new Error("not image");
        this.$refs.photoImage.src = shotImage;
        this.isShotFreeze = true;
        if (this.countShotAttention == 0) {
          this.countShotAttention = 1;
        }
        gtag('event', 'photo', { 'event_category': 'shot' });

      } catch (error) {
        console.log(error);
      }

    },
    execRetry() {
      console.log("execRetry");
      try {
        this.isShotFreeze = false;
        if (this.countShotAttention == 1) {
          this.countShotAttention = 0;
        }
        this.$refs.photoImage.src = undefined;
      } catch (error) {
        console.log(error);

      }

    },
    async changeFrame() {
      let targetFrameArea;
      if (!this.isShotFreeze) {
        targetFrameArea = this.$refs.prevFrameArea;
      } else {
        targetFrameArea = this.$refs.shotFrameArea;
      }

      if (targetFrameArea) {
        targetFrameArea.classList.add("loading");
        setTimeout(() => {
          this.changeFrameExec();
          targetFrameArea.classList.remove("loading");
        }, 300);
      } else {
        this.changeFrameExec();
      }

      gtag('event', 'photo', { 'event_category': 'changeframe' });

    },
    async changeFrameExec() {
      this.countShotAttention = -1;

      if (this.viewFrame === 1) {
        this.viewFrame = 0;
      } else {
        this.viewFrame = 1;
      }
      if (this.isShotFreeze) {
        const shotImage = await this.$stamp.getChangeShotCameraData(this.frameImage[this.viewFrame]);
        if (!shotImage) throw new Error("not image");
        this.$refs.photoImage.src = shotImage;
      }
    },

    goLink(url) {
      window.open(url, '_blank');
    },
  },
  watch: {
    viewFrame: function (val) {
      let appDom = document.getElementById("app");
      if (val === 0) {
        appDom.classList.remove("photoBgB");
        appDom.classList.add("photoBgA");
        this.$stamp.changeFrame(this.frameImage[0], -0.17);
      } else {
        appDom.classList.remove("photoBgA");
        appDom.classList.add("photoBgB");
        this.$stamp.changeFrame(this.frameImage[1], 0.0);
      }
      // this.resize();
    }
  }
};
</script>
